<template>
  <b-container fluid>
    <iq-card class="mt-2" v-if="isOrgAdmin">
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.pump_information_dashboard') }}</h4>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col>
                    <b-row>
                        <b-col lg="12" class="d-flex justify-content-center mb-1">
                            <h4 class="font-weight-bolder" style="color: #08a20c;">{{ currentOrgName }}</h4>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </template>
    </iq-card>
    <iq-card class="mt-2" v-else-if="upazilaId">
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.pump_information_dashboard') }}</h4>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col>
                    <b-row>
                        <b-col lg="4" class="text-center mb-1">
                            <div>
                                <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('globalTrans.district') }} : {{ getDistrictName }}</h4>
                            </div>
                        </b-col>
                        <b-col lg="4" class="text-center mb-1">
                            <div>
                                <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('globalTrans.upazila') }} : {{ upazilaName }}</h4>
                            </div>
                        </b-col>
                        <b-col lg="4" class="text-center mb-1">
                            <div>
                                <h4 class="font-weight-bolder" style="color: #08a20c;">{{ $t('pump_install.office') }} : {{ getOfficeName }}</h4>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </template>
    </iq-card>
    <iq-card class="mt-2" v-else>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('pump_install.pump_information_dashboard') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <div class="col-2"></div>
          <div class="col-8 justify-content-center">
            <b-row>
              <b-form-group
                label-cols-sm="5"
                label-for="org_id"
                label-class="text-right"
                class="col-10 mb-0"
                >
                <template v-slot:label :class="text-align-right">
                    {{$t('irrigation_task.Please Select Organization')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="organizationProfileList"
                  @input="filterData()"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <!-- <b-button  type="button" variant="primary" v-on:click="filterData()">
                {{ $t('globalTrans.search') }}
              </b-button> -->
            </b-row>
          </div>
        </b-row>
      </template>
    </iq-card>
    <b-row>
        <b-overlay :show="loadingState" class="col-12">
            <b-row v-if="data">
                <b-col md="4" sm="6" class="mt-3">
                    <div class="card">
                        <div class="card-header bg-primary text-white">
                            {{$t('irriPumpInformation.TotalPump')}}
                        </div>
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div class="col-12">
                                  <router-link to="/irrigation/pump-information/pump-info-list">
                                    <div class="row">
                                        <div class="col-6"><i class="fas fa-tasks fa-4x text-primary"></i></div>
                                        <div class="col-6"><h1>{{$n(data.total_pump)}}</h1></div>
                                    </div>
                                  </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col md="4" sm="6" class="mt-3">
                    <div class="card">
                        <div class="card-header bg-success text-white">
                            {{$t('irriPumpInformation.TotalOperator')}}
                        </div>
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div class="col-12">
                                  <router-link to="/irrigation/pump-information/pump-operator-list">
                                    <div class="row">
                                        <div class="col-6"><i class="fas fa-tasks fa-4x text-success"></i></div>
                                        <div class="col-6"><h1>{{$n(data.total_operator)}}</h1></div>
                                    </div>
                                  </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="card text-center" v-else>
                <div class="card-header">
                    <h4 class="text-danger">{{$t('globalTrans.No Data Available !!')}}</h4>
                </div>
            </div>
        </b-overlay>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      search: {
        org_id: 0,
        upazilla_id: 0
      },
      data: {
        total_pump: 0,
        total_operator: 0
      },
      search_field: '',
      isOrgAdmin: false,
      upazilaId: 0,
      officeName: '',
      districtId: 0,
      loadingState: false
    }
  },
  computed: {
    currentOrgName () {
      return (this.$i18n.locale === 'bn') ? this.currentOrg.text_bn : this.currentOrg.text
    },
    upazilaName () {
        let returnVal = ''
        const upazilas = this.$store.state.commonObj.upazilaList
        const upazila = upazilas.find(el => el.value === this.upazilaId)
        if (upazila) {
          returnVal = (this.$i18n.locale === 'bn') ? upazila.text_bn : upazila.text
        }
        return returnVal
    },
    getDistrictName () {
        const district = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(this.districtId))
        if (typeof district === 'undefined') {
          return ''
        } else {
          return district.text
        }
    },
    getOfficeName () {
      return (this.officeName) ? this.officeName : ''
    },
    organizationProfileList () {
      return this.$store.state.commonObj.organizationProfileList
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    currentLocale () {
      return this.$i18n.locale
    },
    ...mapGetters({
      authUser: 'Auth/authUser',
      commonObj: 'commonObj'
    })
  },
  created () {
    this.checkUser()
  },
  mounted () {
    core.index()
  },
  methods: {
    checkUser () {
      const user = this.$store.state.Auth.authUser
      this.isOrgAdmin = user.is_org_admin
       if (user.role_id === 0 && user.is_org_admin === 1) {
          this.search.org_id = user.org_id
          this.filterData()
          const org = this.$store.state.orgList.find(el => el.value === user.org_id)
          if (org) {
              this.currentOrg = org
          }
      } else if (user.role_id === 0) {
          this.search.org_id = user.office_detail.org_id
          this.search.upazilla_id = user.office_detail.upazilla_id
          this.upazilaId = user.office_detail.upazilla_id
          this.districtId = user.office_detail.district_id
          this.officeName = (this.$i18n.locale === 'bn') ? user.office_detail.office_name_bn : user.office_detail.office_name
          this.filterData()
      } else {
        this.filterData()
      }
    },
    filterData () {
      this.loadingState = true
      if (this.search.org_id !== 0) {
        const orgList = this.$store.state.commonObj.organizationProfileList
        const moduleData = orgList.filter(org => org.value === this.search.org_id)
        this.search_field = moduleData[0]
      }
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-information/dashboard', this.search).then(response => {
        this.data = response.data
        this.loadingState = false
      }, error => {
        if (error) {
          //
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        this.loadingState = false
      })
    }
  }
}
</script>
<style scoped>
  .user-section .iq-card {
    border: solid 1px #e6e6e6
  }
  .role-section .iq-card {
    border: solid 1px #e6e6e6
  }
</style>
